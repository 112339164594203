













import { Component, Inject, Prop, Vue } from 'vue-property-decorator'
import { defaultProvider, IS_MOBILE_PROVIDER_KEY } from '../../../../../support'
import { ProductSpecification } from '../../../../../contexts'
import { Step } from '../../../../../dsl/atoms/Steps'

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
@Component<StepSection>({
  name: 'StepSection'
})
export class StepSection extends Vue {
  @Inject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  @Prop({ required: true, type: Object })
  public step!: ProductSpecification['stepSection']

  public get steps (): Step[] {
    return this.step.items.map(item => {
      return { label: item }
    })
  }
}

export default StepSection
